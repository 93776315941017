import React, { useContext } from "react";
import dayjs from "dayjs";

import { sortOfDayOff } from "configs/sortOfDayOff";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import { DayOffInfoContext } from "./AnalyticsContainer";

const CustomContainer = styled(Container)({
  height: "100%",
});

const BackgroundGrid = styled(Grid)({
  height: "100%",
});

const PaperContainer = styled(Grid)({
  minHeight: "90vh",
  padding: "10px",
});

export default function AnalyticsBlock() {
  return (
    <CustomContainer maxWidth="xl">
      <BackgroundGrid container alignContent="center">
        <PaperContainer container item component={Paper}>
          <Grid container item alignContent="flex-start" rowGap={2}>
            <DayOffInfoTitle />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <DayOffInfoList />
          </Grid>
        </PaperContainer>
      </BackgroundGrid>
    </CustomContainer>
  );
}

function DayOffInfoTitle() {
  const { vacationState, queryDateRef, queryDateState, queryDateChangeFunc, queryDateSetFunc } = useContext(DayOffInfoContext);

  return (
    <Grid container item rowGap={1}>
      <Grid container item>
        <Grid item xs={3}>
          <Typography variant="h5" align="center">
            전체
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" align="center">
            사용
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" align="center">
            잔여
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5" align="center">
            기준 일자
          </Typography>
        </Grid>
      </Grid>

      <Grid container item alignItems="center">
        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {vacationState.total + "시간" + " (" + vacationState.total / 8 + "일)"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {vacationState.used + "시간" + " (" + vacationState.used / 8 + "일)"}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" align="center">
            {vacationState.leftover + "시간" + " (" + vacationState.leftover / 8 + "일)"}
          </Typography>
        </Grid>
        <Grid container item xs={3} justifyContent="center" alignItems="center">
          <Grid item xs="auto">
            <InputBase inputRef={queryDateRef} type="date" value={queryDateState.format("YYYY-MM-DD")} onChange={queryDateChangeFunc} />
          </Grid>
          <Grid item xs="auto">
            <IconButton size="small" onClick={queryDateSetFunc}>
              <ChangeCircleIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function DayOffInfoList() {
  const { vacationState } = useContext(DayOffInfoContext);

  const sortedList = vacationState.details.sort((a, b) => dayjs(b.startDate).format("YYYYMMDD") - dayjs(a.startDate).format("YYYYMMDD"));

  return (
    <Grid container item rowGap={1}>
      <Grid container item>
        <Grid item xs>
          <Typography variant="h5" align="center">
            종류
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" align="center">
            시작일
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" align="center">
            종료일
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" align="center">
            전체일수
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" align="center">
            연차반영일수
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" align="center">
            요약
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        {sortedList.map((item, index) => {
          return (
            <Grid container item key={"vacation" + index}>
              <Grid item xs>
                <Typography variant="h6" align="center">
                  {sortOfDayOff[item.sort] ? sortOfDayOff[item.sort] : item.sort}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" align="center">
                  {item.startDate + " " + item.startTime}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" align="center">
                  {item.endDate + " " + item.endTime}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" align="center">
                  {item.days === 8 ? "8시간(1일)" : item.days + "시간" + " (" + item.days / 8 + "일)"}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" align="center">
                  {item.calcDays === 8 ? "8시간(1일)" : item.calcDays + "시간" + " (" + item.calcDays / 8 + "일)"}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" align="center">
                  {item.title}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
