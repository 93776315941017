export const officialDayOff = [
  {
    sort: "민방위",
    regularEx: /민방위/gi,
    time: 4,
  },
  {
    sort: "예비군",
    regularEx: /예비군|훈련|작계/gi,
    time: 8,
  },
  {
    sort: "건강 검진",
    regularEx: /검진/gi,
    time: 4,
  },
  {
    sort: "병가",
    regularEx: /병가/gi,
    time: 8,
  },
];
