import axios from "axios";

const holidayUrl = "https://www.googleapis.com/calendar/v3/calendars/ko.south_korea.official%23holiday%40group.v.calendar.google.com/events?key=" + process.env.REACT_APP_GOOGLE_CALENDAR_AUTH_KEY;
// const weekendUrl = "https://www.googleapis.com/calendar/v3/calendars/c_8d64826b1da529ed8d762cdbbf57b175551ce49ecf205266112be0a2e1642e9c@group.calendar.google.com/events?key=" + process.env.REACT_APP_GOOGLE_CALENDAR_AUTH_KEY;

const api = axios.create({
  headers: {
    "Content-type": "application/json; charset=UTF-8",
  },
});

// @ts-check

/**
 * @description Google Calendar API 서버에 국경일 정보 요청
 * @param {{ minDate: string, maxDate: string, callback: (data: object) => void}}
 * @returns {Promise<object>}
 */

export const GetHolidays = ({ minDate, maxDate, callback }) => {
  const queryFunc = api.get(`${holidayUrl}&timeMin=${minDate}&timeMax=${maxDate}`).then((post) => post.data);

  queryFunc.then((data) => {
    callback(data);
  });
  return queryFunc;
};

// export const GetWeekends = (minDate, maxDate, callback) => {
//   const queryFunc = api.get(`${weekendUrl}&timeMin=${minDate}&timeMax=${maxDate}`).then((post) => post.data);

//   queryFunc.then((data) => {
//     callback(data);
//   });
//   return queryFunc;
// };
