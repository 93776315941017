import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

const CustomContainer = styled(Container)(({ theme }) => ({
  minHeight: "100vh",
}));

export default function Loading() {
  return (
    <CustomContainer maxWidth="md">
      <Backdrop open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    </CustomContainer>
  );
}
