import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainView from "../views/MainView";
import AuthView from "../views/AuthView";
import AnalyticsView from "../views/AnalyticsView";

const createRoutes = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainView />} />
        <Route exact path="/auth" element={<AuthView />} />
        <Route exact path="/analytics" element={<AnalyticsView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default createRoutes;
