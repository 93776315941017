import axios from "axios";

const url = "https://asia-northeast3-project-day-off.cloudfunctions.net/function-day-off_filtered";

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
  },
});

const requestDayOffDataFormData = {
  type: "request_dayoff",
  sub_type: "read",
};

// @ts-check

/**
 * @description 서버에 로그인 한 유저의 휴가 데이터 요청
 * @param {{ token: string, callback: (data: object) => void}}
 * @returns {Promise<object>}
 */
export const RequestDayOffData = ({ token }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...requestDayOffDataFormData, token: token })).then((post) => post.data);

  return queryFunc;
};
