import axios from "axios";

// const tokenUrl = "/oauth/token";
const tokenUrl = "https://openapi.swit.io/oauth/token";

const tokenApi = axios.create({
  baseURL: tokenUrl,
  headers: {
    "Content-type": "application/x-www-form-urlencoded;",
  },
});

// @ts-check
/**
 * @description Swit 서버에 토큰 요청
 * @param {string} activateCode
 * @param {(data: object) => void} callBack
 * @returns {Promise<object>}
 */

export const RequestToken = (activateCode) => {
  const clientId = process.env.REACT_APP_SWIT_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_SWIT_CLIENT_SECRET;

  const url = window.location.origin;

  const queryFunc = tokenApi
    .post("", {
      grant_type: "authorization_code",
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: `${url}/auth`,
      code: activateCode,
    })
    .then((post) => post.data)
    .catch((error) => {
      console.log(error);
    });

  return queryFunc;
};

// @ts-check

/**
 * @description Swit 서버에 토큰 갱신 요청
 * @param {string} refreshToken
 * @returns {Promise<object>}
 */

export const RequestRefreshToken = (refreshToken) => {
  const clientId = process.env.REACT_APP_SWIT_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_SWIT_CLIENT_SECRET;

  const queryFunc = tokenApi
    .post("", {
      grant_type: "refresh_token",
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: refreshToken,
    })
    .then((post) => post.data);

  return queryFunc;
};
