import React, { useState, useEffect, createContext, useRef } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { RequestDayOffData } from "utils/requestToSrv";
import { RequestRefreshToken } from "utils/switUtils";
import { GetHolidays } from "utils/googleCalendarUtils";

import Loading from "components/Loading";

import { officialDayOff } from "configs/officialDayOff";

import AnalyticsBlock from "./AnalyticsBlock";

export const DayOffInfoContext = createContext();

export default function AnalyticsContainer() {
  dayjs.extend(isBetween);
  const queryClient = useQueryClient();
  // Token refresh
  const refreshToken = JSON.parse(sessionStorage.getItem("token")).refresh_token;

  const refreshedToken = useMutation({
    mutationFn: () => RequestRefreshToken(refreshToken),
    onSuccess: (data) => {
      queryClient.setQueryData(["token"], data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (!queryClient.getQueryData(["token"])) {
      refreshedToken.mutate();
    } else {
      dayOffData.mutate();
    }
  }, []);

  // request day off data
  const requestDayOffDataCallback = (data) => {
    queryDateSetState(dayjs(data.data.baseDate));
    daysCalcFunc(data, dayjs(data.data.baseDate));
  };

  const dayOffData = useMutation({
    mutationFn: () => RequestDayOffData({ token: queryClient.getQueryData(["token"]).access_token }),
    onSuccess: (data) => {
      requestDayOffDataCallback(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // query date
  const queryDateRef = useRef();
  const [queryDateState, queryDateSetState] = useState(dayjs("1900-01-01"));

  // @ts-check
  /**
   * @description 기준 일자 state 변경 함수
   * @returns {void}
   */

  const queryDateChangeFunc = () => {
    queryDateSetState(dayjs(queryDateRef.current.value));
  };

  // @ts-check
  /**
   * @description 기준 일자 설정 함수
   * @returns {void}
   */

  const queryDateSetFunc = async () => {
    vacationSetState({ total: 120, used: 0, leftover: 120, details: [] });
    daysCalcFunc(dayOffData.data, queryDateState);
  };

  // get holidays

  const [holidayState, holidaySetState] = useState({
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    10: {},
    11: {},
    12: {},
  });

  const year = queryDateState.year();
  const thisYear = dayjs().year();
  const minDate = dayjs(`${year}-01-01`).toISOString();
  const maxDate = dayjs(`${thisYear}-12-31`).toISOString();

  const holiday = useQuery({
    queryKey: ["holiday"],
    queryFn: () => GetHolidays({ minDate: minDate, maxDate: maxDate, callback: holidayStateSet }),
  });

  // @ts-check
  /**
   * @description 서버에서 받아온 공휴일 데이터 설정 함수
   * @param {object} holidayData
   * @returns {void}
   */

  const holidayStateSet = (holidayData) => {
    holidayData.items.forEach((item) => {
      const month = dayjs(item.start.date).month() + 1;
      const date = dayjs(item.start.date).date();

      holidaySetState((prev) => {
        return {
          ...prev,
          [month]: {
            ...prev[month],
            [date]: item.summary,
          },
        };
      });
    });
  };

  const [vacationState, vacationSetState] = useState({ total: 120, used: 0, leftover: 120, details: [] });

  // @ts-check
  /**
   * @description 휴가 일수 계산 함수
   * @param {object} item
   * @param {object} startDate
   * @param {object} endDate
   * @param {number} startMonth
   * @param {number} endMonth
   * @returns {void}
   */

  const daysCalculator = (item, startDate, endDate, startMonth, endMonth) => {
    const days = endDate.diff(startDate, "days");
    const time = endDate.diff(startDate, "hours") === 0 ? 8 : endDate.diff(startDate, "hours");
    let calcDays = days === 0 ? time : (days + 1) * 8;
    let sort = time >= 8 ? "whole" : time;

    if (!startDate.isSame(endDate)) {
      // 공휴일 제외
      if (item.start_date !== "" && item.end_date !== "") {
        Object.keys(holidayState[startMonth]).forEach((holidayItem) => {
          const holidayDate = dayjs(year + "." + startMonth + "." + holidayItem, "YYYY.MM.DD");
          if (holidayDate.isBetween(startDate, endDate)) {
            //  토요일, 일요일은 추후 제외
            if (holidayDate.format("ddd") !== "Sat" && holidayDate.format("ddd") !== "Sun") {
              calcDays = calcDays - 8;
            }
          }
        });

        Object.keys(holidayState[endMonth]).forEach((holidayItem) => {
          const holidayDate = dayjs(year + "." + endMonth + "." + holidayItem, "YYYY.MM.DD");
          if (holidayDate.isBetween(startDate, endDate)) {
            //  토요일, 일요일은 추후 제외
            if (holidayDate.format("ddd") !== "Sat" && holidayDate.format("ddd") !== "Sun") {
              calcDays = calcDays - 8;
            }
          }
        });

        // 토요일, 일요일 제외
        for (let i = 0; i < days; i++) {
          let date = dayjs(startDate).add(i, "days");
          if (date.format("ddd") === "Sat" || date.format("ddd") === "Sun") {
            calcDays = calcDays - 8;
          }
        }
      }
    }

    officialDayOff.forEach((dayOffItem) => {
      if (item.title.match(dayOffItem.regularEx)) {
        calcDays = calcDays - dayOffItem.time;
        sort = dayOffItem.sort;
      }
    });

    // 휴가 일수 계산
    vacationSetState((prevState) => ({
      ...prevState,
      used: prevState.used + calcDays,
      leftover: prevState.leftover - calcDays,
      details: [
        ...prevState.details,
        {
          sort: sort,
          startDate: startDate.format("YYYY.MM.DD"),
          startTime: startDate.format("HH:mm"),
          endDate: endDate.format("YYYY.MM.DD"),
          endTime: endDate.format("HH:mm"),
          title: item.title,
          days: days === 0 ? time : (days + 1) * 8,
          calcDays: calcDays,
        },
      ],
    }));
  };

  // @ts-check
  /**
   * @description 휴가 일수 계산 함수
   * @param {object} data
   * @returns {void}
   */

  const daysCalcFunc = (data, date) => {
    try {
      data.data.tasks.forEach((item) => {
        // 일자 설정 안 한 경우
        if (item.start_date === "" || item.end_date === "") {
          const title = item.title;
          const splitTitle = title.split(/[\-\_\~\(\)]/g);
          const firstNum = splitTitle[0];
          const secondNum = splitTitle[1].replace(/[^0-9]/g, "");
          const startDate = firstNum.length === 6 ? dayjs("20" + firstNum, "YYYY.MM.DD") : dayjs(firstNum, "YYYY.MM.DD");
          const startMonth = startDate.month() + 1;
          const endDate = secondNum === "" ? startDate : secondNum.length < 6 ? startDate : secondNum.length === 6 ? dayjs("20" + secondNum, "YYYY.MM.DD") : dayjs(secondNum, "YYYY.MM.DD");
          const endMonth = secondNum === "" ? startMonth : endDate.month() + 1;

          if (endDate.isBefore(date)) {
            return;
          } else {
            daysCalculator(item, startDate, endDate, startMonth, endMonth);
          }
        } else {
          if (dayjs(item.end_date).isBefore(date)) {
            return;
          } else {
            // 종료일만 설정한 경우
            if (item.start_date === "" && item.end_date !== "") {
              const endDate = dayjs(item.end_date);
              const endMonth = endDate.month() + 1;
              daysCalculator(item, endDate, endDate, endMonth, endMonth);
              // 일자 설정을 모두 한 경우
            } else {
              const startDate = dayjs(item.start_date);
              const startMonth = startDate.month() + 1;
              const endDate = dayjs(item.end_date);
              const endMonth = endDate.month() + 1;

              daysCalculator(item, startDate, endDate, startMonth, endMonth);
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const dayOffInfoSet = {
    vacationState: vacationState,
    queryDateRef: queryDateRef,
    queryDateState: queryDateState,
    queryDateChangeFunc: queryDateChangeFunc,
    queryDateSetFunc: queryDateSetFunc,
  };

  if (dayOffData.isPending || holiday.isPending) {
    return <Loading />;
  } else {
    return (
      <DayOffInfoContext.Provider value={dayOffInfoSet}>
        <AnalyticsBlock />;
      </DayOffInfoContext.Provider>
    );
  }
}
