import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }),
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle3: "p",
          body1: "p",
          body2: "p",
          body3: "p",
          caption1: "p",
          caption2: "p",
          caption3: "p",
          button1: "p",
          button2: "p",
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1830,
    },
  },
  palette: {
    primary: {
      main: "#ED1C24",
    },
    secondary: {
      main: "#FFFFFF",
    },
    alert: {
      main: "#E6B300",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
    background: {
      default: "#0F2841",
    },
    grey: {
      50: "#F8F8F8",
      100: "#EAECEE",
      200: "#DCDFE1",
      300: "#D9D9D9",
      400: "#9DA0A1",
      500: "#606264",
      600: "#404244",
      700: "#212121",
      800: "#151515",
      900: "#0C0C0C",
    },
    neutral: {
      main: "#0C0C0C",
      contrastText: "#FDFDFD",
    },
    naver: {
      main: "#32b34a",
    },
    kakao: {
      main: "#FFE800",
    },
    google: {
      main: "#F5F6F7",
    },
  },
  typography: {
    fontFamily: ["D2Coding", "Noto Sans KR"].join(","),

    h1: {
      fontSize: 66,
      fontWeight: 700,
      textTransform: "uppercase",
      lineHeight: "77px",
      fontFamily: "D2CodingBold",
    },

    h2: {
      fontSize: 54,
      fontWeight: 700,
      textTransform: "uppercase",
      lineHeight: "63px",
      fontFamily: "D2CodingBold",
    },
    h3: {
      fontSize: 38,
      fontWeight: 700,
      textTransform: "uppercase",
      lineHeight: "44px",
      fontFamily: "D2CodingBold",
    },
    h4: {
      fontSize: 27.5,
      fontWeight: 700,
      letterSpacing: "-0.03em",
      lineHeight: "32px",
      fontFamily: "D2CodingBold",
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "D2CodingBold",
      lineHeight: "18px",
    },
    h6: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: "D2CodingBold",
      lineHeight: "18px",
    },

    subtitle1: {
      fontSize: 26,
      fontWeight: 700,
      letterSpacing: "-0.03em",
      lineHeight: "30px",
      fontFamily: "D2CodingBold",
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "23px",
      fontFamily: "D2CodingBold",
    },
    subtitle3: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "21px",
      letterSpacing: "0.02em",
      fontFamily: "D2CodingBold",
    },
    body1: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "18px",
    },
    body2: {
      fontSize: 15,
      fontWeight: 700,
      lineHeight: "22px",
      letterSpacing: "0.02em",
      fontFamily: "D2CodingBold",
    },
    body3: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "20px",
    },
    caption1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "18px",
    },
    caption2: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: "D2CodingBold",
      lineHeight: "12px",
    },
    caption3: {
      fontSize: 10,
      fontWeight: 700,
      lineHeight: "12px",
    },
    button1: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "21px",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      fontFamily: "D2CodingBold",
    },
    button2: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "14px",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      fontFamily: "D2CodingBold",
    },
  },
});

export default theme;
