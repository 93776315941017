import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { RequestToken } from "../../utils/switUtils";

import AuthBlock from "./AuthBlock";

export default function AuthContainer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const activateCode = searchParams.get("code");

  // @ts-check
  /**
   * @description Callback function for RequestToken
   * @param {object} data
   * @returns {void}
   */

  const callBack = (data) => {
    sessionStorage.setItem("token", JSON.stringify(data));
    queryClient.setQueryData(["token"], data);
    navigate("/analytics");
  };

  useEffect(() => {
    token.mutate();
  }, []);

  const token = useMutation({
    mutationFn: () => RequestToken(activateCode),
    onSuccess: (data) => {
      callBack(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return <AuthBlock />;
}
